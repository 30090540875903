import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { environment } from '../../environments/environment';

export function validateDate(control: AbstractControl) {
  if (typeof control !== 'undefined' && control.value !== '' && control.value !== null) {
    const isValid = moment(control.value, environment.customDateTimeFormat.parseInput, true).isValid();
    console.log(isValid);
    return isValid ? null : { valid: false };
  }
  return null;
}

export class CustomValidator {
  static dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = moment(control.value, environment.customDateTimeFormat.parseInput);

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment(date);

      return controlDate.isAfter(validationDate)
        ? null
        : {
            'date-minimum': {
              'date-minimum': validationDate.format(environment.customDateTimeFormat.parseInput),
              actual: controlDate.format(environment.customDateTimeFormat.parseInput),
            },
          };
    };
  }
}
